import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  target: any;
  active: any = '#home';
  currentRoute: any;
  sections: any;
  navLi: any;
  current: any = '';
  scrolled: boolean = false;
  email ='ai@thekraftors.com'
  baseurl = 'https://thekraftors.com/'
  constructor(public router: Router) {
    // Initial route check
    if (router.url !== '/index-four' && router.url !== '/index-five' && router.url !== '/index-nine') {
      this.currentRoute = true;
    }
  }

  ngOnInit() {
    // Initialize sections and navLi
    this.sections = document.querySelectorAll('section');
    this.navLi = document.querySelectorAll('nav .container .navigation ul li');

    // Subscribe to router events
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Handle route change logic here
        this.handleRouteChange();
      }
    });
  }

  // Method to handle window scroll
  @HostListener('window:scroll', ['$event'])
  onscroll() {
    this.sections.forEach((section: any) => {
      const sectionTop = section.offsetTop;
      if (window.scrollY >= sectionTop - 60) {
        this.current = section.getAttribute('id');
        this.scrolled = window.scrollY > 50;
      }
    });

    this.navLi.forEach((li: any) => {
      if (li.classList.contains(this.current)) {
        li.classList.add('active');
      } 
      else {
        li.classList.remove('active');
      }
    });
  }

  // Method to navigate to a specific route
  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  // Method to toggle menu visibility
  toggleMenu() {
    let ele = document.getElementById('menu-collapse') as any;
    ele.classList.toggle('hidden');
  }

  // Method to handle route change and update active link
  handleRouteChange() {
    const currentUrl = this.router.url;
    this.navLi.forEach((li: any) => {
      if (li.querySelector('a')?.getAttribute('routerLink') === currentUrl) {
        li.classList.add('active');
      } else {
        li.classList.remove('active');
      }
    });
  }

  // Smooth scroll to section
  ScrollIntoView(elem: string) {
    this.active = elem;
    let ele = document.querySelector(elem) as any;
    ele.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
