<!-- Start -->
<section class="relative md:py-24 py-16" id="blog">
    <div class="container">
        <div class="grid grid-cols-1 pb-8 text-center">
            <h6 class="text-orange-600 text-base font-medium uppercase mb-2">Blogs</h6>
            <h3 class="mb-4 md:text-2xl text-xl font-medium dark:text-white">Latest News</h3>

            <p class="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Launch your campaign and benefit from our
                expertise on designing and managing conversion centered Tailwind CSS html page.</p>
        </div><!--end grid-->

        <div class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6 mt-8">
            <div *ngFor="let item of blogData"
                class="blog relative rounded-md shadow shadow-slate-200 dark:shadow-slate-800 overflow-hidden">
                <img [src]="item.image" alt="">

                <div class="content p-6">
                    <a href="blog-detail"
                        class="text-lg hover:text-orange-600 dark:text-white dark:hover:text-orange-600 transition-all duration-500 ease-in-out font-medium">
                        {{item.title}}</a>
                    <p class="text-slate-400 mt-3">{{item.text}}
                    </p>

                    <div class="mt-5">
                        <a href="blog-detail"
                            class="btn btn-link hover:text-orange-600 dark:hover:text-orange-600 after:bg-orange-600 dark:text-white transition duration-500">Read
                            More <i class="uil uil-arrow-right"></i></a>
                    </div>
                </div>
            </div>

        </div><!--end grid-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->