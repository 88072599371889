import { Component, OnInit } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient, HttpClientModule, HttpHeaders, HttpEventType, HttpEvent } from '@angular/common/http';
// import { CallApiService } from '../../shared/services/callapi.service';
@Component({
  selector: 'app-url-api',
  // standalone: true,
  // imports: [],
  templateUrl: './url-api.component.html',
  styleUrl: './url-api.component.scss'
})
export class UrlApiComponent implements OnInit {
  userInput: string = '';
  chatbotResponse: string = '';
  errorMessage: string = '';
  ngOnInit(): void {
    this.callApiMethod();
  }
  constructor(private http: HttpClient) { }

  // sendMessage() {
  //   const messageData = { message: this.userInput }; // Adjust based on your API's expected payload
  //   console.log("hii");

  // }

  callApiMethod() {
    console.log("hii");
    const obj = {
      "main_choice": "1"
    }
    this.http.post<any>(`/chatbot/chatbot/`, obj).subscribe(
      response => {
        console.log('Response from API:', response);
      },
      error => {
        console.error('Error occurred:', error);
      }
    );
  }
}
