<div class="bg-gradient-to-b from-gray-50 to-gray-100 enhance-image px-4 sm:px-6 lg:px-8">
  <div class="max-w-7xl mx-auto" >
    <!-- <h1
      class="text-5xl font-extrabold mb-8 text-center bg-clip-text enhance-image-title text-transparent bg-gradient-to-r from-orange-500 to-pink-500">
      Enhance Your Image with AI
    </h1> -->

    <h1
      class="text-5xl font-extrabold mb-8 text-center bg-clip-text enhance-image-title text-transparent bg-gradient-to-r from-orange-500 to-pink-500">
      Transform Images with Free AI Image Enhancer
    </h1>

    <!-- <p class="text-center p-5 max-w-3xl mx-auto text-gray-700 leading-relaxed text-lg mb-12">
      Effortlessly enhance image quality in seconds with state-of-the-art AI technology.
      Use our advanced photo enhancer to improve clarity and bring out even the tiniest details in your images.
    </p> -->

    <p class="text-center p-5 max-w-3xl mx-auto text-gray-700 leading-relaxed text-lg mb-12">
      Boost your photos in seconds with our free AI image enhancer! It improves clarity and reveals details, perfect for anyone wanting their images to shine.
    </p>

    <div class="flex flex-wrap justify-center items-stretch w-full max-w-6xl gap-8 mx-auto" >
      <!-- File Upload Section -->
      <div class="{{uploadfileDiv}} w-full md:w-1/2" >

        <div
          class="bg-white rounded-2xl shadow-xl p-8 flex flex-col items-center h-full transition-all duration-300 hover:shadow-2xl border-2 border-orange-400">
          <!-- Error message -->
          <div *ngIf="fileTooLarge">
            <span class="flex top-5 left-0 right-0 bg-red-600 text-white text-center p-2 rounded-xl">
              Please upload image less than 5 MB.</span>
          </div>
          <div class="flex items-center space-x-2 mb-6">
            <svg class="w-20 h-20 text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z">
              </path>
            </svg>
          </div>
          <h5 class="text-3xl font-bold mb-6 text-orange-500">AI Enhance</h5>
          <div
            class="w-full border-dashed border-4 border-orange-300 rounded-2xl p-8 flex flex-col items-center bg-orange-50 transition-all duration-300 hover:bg-orange-100 mb-8">
            <!-- <p class="text-orange-600 mb-4 text-xl font-medium">Drag and drop a file</p>
            <p class="text-orange-600 mb-4 font-medium">OR</p> -->
            <input type="file" id="file-upload" class="hidden" (change)="onchnageprofile($event)"
              accept="image/png, image/jpeg">
            <label for="file-upload"
              class="bg-gradient-to-r from-orange-500 to-pink-500 text-white py-3 px-8 rounded-full cursor-pointer hover:from-orange-600 hover:to-pink-600 transition ease-in-out duration-300 text-lg font-semibold flex items-center shadow-lg">
              <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
              </svg>
              Browse files
            </label>
          </div>
          <div class="mb-2 space-y-4 w-full">
            <label
              class="block text-gray-700 hover:text-blue-600 cursor-pointer transition-colors duration-300 flex items-center  bg-gray-50 rounded-lg">
              <input name="options" type="radio" value="1" [(ngModel)]="selectedOption"
                class="mr-3 text-blue-600 focus:ring-blue-500 h-5 w-5">
              <svg class="w-6 h-6 mr-3 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z">
                </path>
              </svg>
              <span class="text-lg">Image Enhancement</span>
            </label>
            <label
              class="block text-gray-700 hover:text-blue-600 cursor-pointer transition-colors duration-300 flex items-center  bg-gray-50 rounded-lg">
              <input name="options" type="radio" value="2" [(ngModel)]="selectedOption"
                class="mr-3 text-blue-600 focus:ring-blue-500 h-5 w-5">
              <svg class="w-6 h-6 mr-3 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M14.121 14.121L19 19m-7-7l7-7m-7 7l-2.879 2.879M12 12L9.121 9.121m0 5.758a3 3 0 10-4.243 4.243 3 3 0 004.243-4.243zm0-5.758a3 3 0 10-4.243-4.243 3 3 0 004.243 4.243z">
                </path>
              </svg>
              <span class="text-lg">Remove Background</span>
            </label>
            <label
              class="block text-gray-700 hover:text-blue-600 cursor-pointer transition-colors duration-300 flex items-center  bg-gray-50 rounded-lg">
              <input name="options" type="radio" value="3" [(ngModel)]="selectedOption"
                class="mr-3 text-blue-600 focus:ring-blue-500 h-5 w-5">
              <svg class="w-6 h-6 mr-3 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
              <span class="text-lg">Both</span>
            </label>
          </div>
          <button (click)="fetchdetails()" href='#Original'[disabled]="isDisabled"
            class="w-full bg-orange-600 text-white py-4 px-6 rounded-xl hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition ease-in-out duration-300 text-lg font-semibold flex items-center justify-center shadow-lg">
            <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z">
              </path>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            Process Image
          </button>
          <p class="text-sm text-gray-500 mt-6 text-center"  #enhancedImage id="enhancedImage">
            By uploading a file, you agree to our
            <span class="text-blue-500 hover:underline cursor-pointer" (click)="navigateToProfile()">Terms of Use</span> and
            <a href="#" class="text-blue-500 hover:underline">Privacy Policy</a>.
          </p>
        </div>
      </div>

      <!-- Image Preview Card -->
      <div class="{{preview}} w-full md:w-1/2 relative max-h-[44rem]" >
        <div
          class="bg-white rounded-2xl shadow-xl overflow-hidden h-full transition-all duration-300 hover:shadow-2xl border-2 border-blue-400">
          <!-- Cross button -->
          <button class="{{closeButtonicon}} right-5 preview-close-btn" (click)="closeButton()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="w-6 h-6 fill-current text-gray-600">
              <!--! Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
              <path
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </button>
          <div class="p-8">
            <h5 class="text-3xl font-bold text-center mb-6 text-blue-500">Preview Image</h5>
            <!-- Radio buttons and process button removed as per your request -->
          </div>
          <img [src]="imageurl" alt="Preview Image" class="w-full h-4/5 object-cover ">
          <!-- <div class="p-8">
            <h5 class="text-3xl font-bold mb-6 text-blue-500">Preview Image</h5>
             Radio buttons and process button removed as per your request
          </div -->
        </div>
      </div>







    </div>
    <div class="container mx-auto px-4 py-8" >
      <div class="flex flex-wrap justify-center items-stretch gap-8" >
        <!-- Original Image Card -->
        <div class="{{previewimage}} w-full md:w-1/2 lg:w-5/12 flex flex-col">
          <div
            class="bg-white rounded-2xl shadow-xl overflow-hidden flex-grow transition-all duration-300 hover:shadow-2xl border-2 border-blue-400 flex flex-col">
            <div class="p-4 bg-blue-50 border-b-2 border-blue-400">
              <h5 class="text-2xl font-bold text-blue-600">Original Image</h5>
            </div>
            <div class="p-4 flex-grow flex items-center justify-center">
              <img [src]="imageurl" alt="Original Image" class="max-w-full max-h-[400px] object-contain rounded-lg">
            </div>

          </div>
        </div>
        <div class="{{attri}} w-full md:w-1/2 lg:w-5/12 flex flex-col" >
          <div
            class="bg-white rounded-2xl shadow-xl overflow-hidden flex-grow transition-all duration-300 hover:shadow-2xl border-2 border-blue-400 flex flex-col">
            <div class="p-4 bg-blue-50 border-b-2 border-blue-400">
              <h5 class="text-2xl font-bold text-blue-600">Enhanced Image</h5>
            </div>
            <!-- Loader -->
            <!-- <div *ngIf="loading" class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
              <div class="loader animate-spin rounded-full h-20 w-20 border-t-4 border-b-4 border-green-500"></div>
            </div> -->
            <div *ngIf="loading" class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
              <div class="flex flex-col items-center w-[300px]">
                <div class="progress-container w-full bg-gray-200 rounded-full h-4 overflow-hidden mb-4">
                  <div class="progress-bar h-full bg-green-500" [ngStyle]="{ width: progress + '%' }"></div>
                </div>
                <!-- <div class="text-gray-700 mt-2">{{ progress }}% Loaded</div> -->
              </div>
            </div>

            <!-- Image -->
            <div class="p-4 flex-grow flex items-center justify-center">
              <img *ngIf="!loading" [src]="imageurld" alt="Enhanced Image"
                class="max-w-full max-h-[400px] object-contain rounded-lg">
            </div>
            <div class="p-4">
              <button (click)="downloadImage()"
                class="w-full bg-gradient-to-r from-green-500 to-green-600 text-white py-3 px-6 rounded-xl hover:from-green-600 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition ease-in-out duration-300 text-lg font-semibold flex items-center justify-center shadow-lg">
                <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
                </svg>
                Download Image
              </button>
            </div>
          </div>
        </div>
        <!-- Enhanced Image Card -->
        <!-- <div class="{{attri}} w-full md:w-1/2 lg:w-5/12 flex flex-col">
          <div class="bg-white rounded-2xl shadow-xl overflow-hidden flex-grow relative transition-all duration-300 hover:shadow-2xl border-2 border-green-400 flex flex-col">
            <div class="p-4 bg-green-50 border-b-2 border-green-400">
              <h5 class="text-2xl font-bold text-green-600">Enhanced Image</h5>
            </div>
            Loader
            <div *ngIf="loading" class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
              <div class="loader animate-spin rounded-full h-20 w-20 border-t-4 border-b-4 border-green-500"></div>
            </div>
            Image
            <div class="p-4 flex-grow flex items-center justify-center">
              <img *ngIf="!loading" [src]="imageurld" alt="Enhanced Image" class="max-w-full max-h-[400px] object-contain rounded-lg">
            </div>
            <div class="p-4">
              <button (click)="downloadImage()" class="w-full bg-gradient-to-r from-green-500 to-green-600 text-white py-3 px-6 rounded-xl hover:from-green-600 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition ease-in-out duration-300 text-lg font-semibold flex items-center justify-center shadow-lg">
                <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
                </svg>
                Download Image
              </button>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>

<!-- How It Works Section -->
<!-- <div class="bg-gray-50 py-16">
  <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
    <h2 class="text-4xl font-extrabold text-center text-gray-900 mb-12">
      Enhance Your Image Quality in 4 Easy Steps
    </h2>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
      <div class="bg-white p-6 rounded-xl shadow-lg transition-transform hover:scale-105">
        <span
          class="flex-shrink-0 w-16 h-16 flex items-center justify-center bg-gradient-to-r from-orange-400 to-pink-500 text-white text-2xl font-bold rounded-full mb-4">1</span>
        <h3 class="text-xl font-semibold text-gray-900 mb-2">Upload Your Photo</h3>
        <p class="text-gray-600">Select an image from your library that you'd like to enhance.</p>
      </div>

      <div class="bg-white p-6 rounded-xl shadow-lg transition-transform hover:scale-105">
        <span
          class="flex-shrink-0 w-16 h-16 flex items-center justify-center bg-gradient-to-r from-orange-400 to-pink-500 text-white text-2xl font-bold rounded-full mb-4">2</span>
        <h3 class="text-xl font-semibold text-gray-900 mb-2">Click on Process Image</h3>
        <p class="text-gray-600">Let our advanced AI work its magic on your image.</p>
      </div>

      <div class="bg-white p-6 rounded-xl shadow-lg transition-transform hover:scale-105">
        <span
          class="flex-shrink-0 w-16 h-16 flex items-center justify-center bg-gradient-to-r from-orange-400 to-pink-500 text-white text-2xl font-bold rounded-full mb-4">3</span>
        <h3 class="text-xl font-semibold text-gray-900 mb-2">Preview the Result</h3>
        <p class="text-gray-600">See the before and after comparison of your enhanced image.</p>
      </div>

      <div class="bg-white p-6 rounded-xl shadow-lg transition-transform hover:scale-105">
        <span
          class="flex-shrink-0 w-16 h-16 flex items-center justify-center bg-gradient-to-r from-orange-400 to-pink-500 text-white text-2xl font-bold rounded-full mb-4">4</span>
        <h3 class="text-xl font-semibold text-gray-900 mb-2">Download</h3>
        <p class="text-gray-600">Save your enhanced image in high quality.</p>
      </div>
    </div>
  </div>
</div> -->

<!-- Custom Contact Us Popup -->
<div *ngIf="showFeedbackPopup" class="custom-popup-overlay">
  <div class="custom-popup-container custom-animated-popup">
    <div class="custom-form-row close-btn-row">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" style="margin-top: -0.5rem">
        <path stroke-linecap="round" stroke-linejoin="round" d="M8 10h8M8 14h4m8-2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>      
    <div class="feedback-cls text-gray-500">Feedback</div>  
    <div clas="custom-close-button">
    <button class="custom-close-button" (click)="closeFeedbackPopup()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="w-6 h-6 fill-current text-gray-600">
        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
      </svg>
    </button>
  </div>
   </div>
    <h2 class="custom-popup-title">Leave a review</h2>
    <!-- <div *ngIf="responseMessage" class="text-green-600 mb-5">{{ responseMessage }}</div> -->
    <form #feedbackForm="ngForm" (submit)="submitFeedback($event)" novalidate>
      <!-- Field Container -->
      <div class="custom-form-row">
        <!-- Name Field -->
        <div class="custom-form-group">
          <label for="name" class="custom-form-label">Name</label>
          <input
            id="name"
            name="name"
            [(ngModel)]="feedbackData.name"
            class="custom-form-control custom-input-field"
            placeholder="Enter your name"
            required
            minlength="3"
            #name="ngModel"
          />
          <div *ngIf="name.invalid && name.touched" class="error-message">
            <div class="text-red-500" *ngIf="name.errors?.['required']">Name is required.</div>
          </div>
        </div>

        <!-- Email Field -->
        <div class="custom-form-group">
          <label for="email" class="custom-form-label">Email</label>
          <input
            id="email"
            name="email"
            type="email"
            [(ngModel)]="feedbackData.email"
            class="custom-form-control custom-input-field"
            placeholder="Enter your email"
            required
            email
            #email="ngModel"
          />
          <div *ngIf="email.invalid && email.touched" class="error-message">
            <div class="text-red-500" *ngIf="email.errors?.['required']">Email is required.</div>
            <div class="text-red-500" *ngIf="email.errors?.['email']">Please enter a valid email address.</div>
          </div>
        </div>
      </div>

      <!-- Message Field -->
      <div class="custom-form-group">
        <label for="message" class="custom-form-label">Message</label>
        <textarea
          id="message"
          name="message"
          [(ngModel)]="feedbackData.message"
          class="custom-form-control custom-textarea-field"
          placeholder="Your message"
          required
          minlength="10"
          #message="ngModel"
        ></textarea>
        <div *ngIf="message.invalid && message.touched" class="error-message">
          <div class="text-red-500" *ngIf="message.errors?.['required']">Message is required.</div>
          <div class="text-red-500" *ngIf="message.errors?.['minlength']">Message must be at least 10 characters long.</div>
        </div>
      </div>


      <!-- Star Rating -->
      <div class="custom-form-group">
        <label class="custom-form-label">Rating</label>
        <div class="star-rating">
          <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
            <svg 
              (click)="feedbackData.rating = star" 
              class="star-rating-icon" 
              [ngClass]="{'filled': (feedbackData.rating ?? 0) >= star}"
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 24 24">
              <path 
                d="M12 .587l3.668 7.568 8.032 1.162-5.8 5.243 1.373 8.045L12 18.897l-7.273 3.834 1.373-8.045-5.8-5.243 8.032-1.162z" 
                class="star-path"/>
            </svg>
          </ng-container>
        </div>
        <div *ngIf="message.invalid && message.touched" class="error-message">
          <div class="text-red-500" *ngIf="message.errors?.['required']">Message is required.</div>
        </div>
      </div>


      <!-- Submit Button -->
      <button
        type="submit"
        class="custom-submit-btn bg-gradient-to-r from-orange-500 to-pink-500 text-white py-3 px-8 rounded-full cursor-pointer hover:from-orange-600 hover:to-pink-600"
        [disabled]="feedbackForm.invalid"
      >
        Submit
      </button>
    </form>
  </div>
</div>



<!-- Feedback Response Popup -->
<div *ngIf="showFeedbackResponsePopup" class="custom-popup-overlay">
  <div class="popup-content">
    <p class="response-message">{{ responseMessage }}</p>
    <button class="response-custom-close-button" (click)="closeFeedbackResponsePopup()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="w-6 h-6 fill-current text-gray-600">
        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
      </svg>
    </button>
  </div>
</div>
