<!-- Start Footer -->
<footer class="py-20 bg-custom-dark-green dark:bg-green-900">
    <div class="container">
        <!-- Footer top -->
        <div class="grid sm:grid-cols-1 md:grid-cols-4 xl:grid-cols-4 gap-5 text-[0.7rem] text-white">
            <div class="">
                <div class="grid grid-cols-12 gap-2">
                    <div class="col-span-2 ">
                        <img src="assets/images/footer/united-states.png" class="l-light" alt="United States">
                    </div>
                    <div class="col-span-10">
                        <div class="footer-add">
                            <p>7315, Wisconsin Ave
                                #400 west Bethesda,
                                Maryland - 20814</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="grid grid-cols-12">
                    <div class="col-span-2 ">
                        <img src="assets/images/footer/india.png" class="l-light" alt="United States">
                    </div>
                    <div class="col-span-10">
                        <div class="footer-add">
                            <p>Nandanam, West of Sree Rama
                                Temple Tripunithura, Ernakulam -
                                682301</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="grid grid-cols-12">
                    <div class="col-span-2 ">
                        <img src="assets/images/footer/india.png" class="l-light" alt="United States">
                    </div>
                    <div class="col-span-10">
                        <div class="footer-add">
                            <p>3rd Floor Shagun Palace,
                                Opp. Mr. Brown Sapru Marg, Hazratganj lko
                                - 226001</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="grid grid-cols-12">
                    <div class="col-span-2 ">
                        <img src="assets/images/footer/united-arab-emirates.png" class="l-light" alt="United States">
                    </div>
                    <div class="col-span-10">
                        <div class="footer-add">
                            <p>Office no 401, Al Zarooni
                                building Bank Street, Bur Dubai, Po
                                Box - 411211</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- footer bottom -->
        <div class="grid sm:grid-cols-1 my-10 md:grid-cols-4 xl:grid-cols-4 gap-5 text-[0.8rem] text-white">
            <div class="grid grid-cols-12 gap-2">
                <div class="col-span-12">
                    <div class="footer-add text-[grey]">
                        <ul>
                            <li>
                                <h6 class="text-white font-bold">SERVICES</h6>
                            </li>
                            <li>
                                <a href="https://thekraftors.com/aiml">AI/ML</a>
                            </li>
                            <li>
                                <a href="https://thekraftors.com/ar">Augmented Reality</a>
                            </li>
                            <li>
                                <a href="https://thekraftors.com/ecommerce">E-commerce</a>
                            </li>
                            <li>
                                <a href="https://thekraftors.com/edtech">edTech</a>
                            </li>
                            <li>
                                <a href="https://thekraftors.com/devops">DevOps</a>
                            </li>
                            <li>
                                <a href="https://thekraftors.com/mobile-flutter-app">Hybrid Mobile Apps</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-12 gap-2">
                <div class="col-span-12">
                    <div class="footer-add text-[grey]">
                        <ul>
                            <li>
                                <h6 class="text-white font-bold">COMPANY</h6>
                            </li>
                            <li>
                                <a href="https://thekraftors.com/about-us">About us</a>
                            </li>
                            <li>
                                <a href="https://thekraftors.com/contact-us">Contact us</a>
                            </li>
                            <li>
                                <a href="https://thekraftors.com/careers">Careers</a>
                            </li>
                            <li>
                                <a href="https://thekraftors.com/privacy-policy">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="https://thekraftors.com/faq">FAQs</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-12 gap-2">
                <div class="col-span-12">
                    <div class="footer-add text-[grey]">
                        <ul>
                            <li>
                                <h6 class="text-white font-bold">PRODUCTS</h6>
                            </li>
                            <li>
                                <a href="https://thekraftors.com/aiml">AI/ML</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-12 gap-2">
                <div class="col-span-12">
                    <div class="footer-add text-[grey]">
                        <ul>
                            <li>
                                <h6 class="text-white font-bold">CASE STUDIES</h6>
                            </li>
                            <li>
                                <a href="https://thekraftors.com/case-study-jewellery-ecommerce">Jewellery
                                    E-commerce</a>
                            </li>
                            <li>
                                <a href="https://thekraftors.com/case-study-indian-edtech">EdTech</a>
                            </li>
                            <li>
                                <a href="https://thekraftors.com/case-study-public-utility">Public Utility</a>
                            </li>
                            <li>
                                <a href="https://thekraftors.com/case-study-private-tourism">Tourism</a>
                            </li>
                            <li>
                                <a href="https://thekraftors.com/case-study-b2b-ecommerce">B2B Commerce</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>


        <!--end grid-->
    </div>
    <hr class="border-[grey]">
    <div class="container">
        <div class="grid sm:grid-cols-1 text-center pt-8 md:grid-cols-2 xl:grid-cols-2 gap-5 text-[0.7rem] text-[grey]">
            <div>
                <p>© 2024 The Kraftors. All Rights Reserved.</p>
            </div>
            <div class="">
                <ul class="flex justify-end">
                    <li>
                        <a href="https://x.com/the_kraftors">
                            <svg class="text-14 w-5 h-5" viewBox="0 0 448 512" fill="#849493">
                                <path
                                    d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z">
                                </path>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/@kraftorswebsolutions7410">
                            <svg xmlns="http://www.w3.org/2000/svg" class="text-14 w-5 h-5" viewBox="0 0 448 512"
                                fill="#849493">
                                <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                <path
                                    d="M282 256.2l-95.2-54.1V310.3L282 256.2zM384 32H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64zm14.4 136.1c7.6 28.6 7.6 88.2 7.6 88.2s0 59.6-7.6 88.1c-4.2 15.8-16.5 27.7-32.2 31.9C337.9 384 224 384 224 384s-113.9 0-142.2-7.6c-15.7-4.2-28-16.1-32.2-31.9C42 315.9 42 256.3 42 256.3s0-59.7 7.6-88.2c4.2-15.8 16.5-28.2 32.2-32.4C110.1 128 224 128 224 128s113.9 0 142.2 7.7c15.7 4.2 28 16.6 32.2 32.4z">
                                </path>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/thekraftors/">
                            <svg class="text-14 w-5 h-5" viewBox="0 0 512 512" fill="#849493">
                                <path
                                    d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z">
                                </path>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="https://in.linkedin.com/company/kraftors-web-solutions-pvt.-ltd.">
                            <svg class="text-14 w-5 h-5" viewBox="0 0 448 512" fill="#849493">
                                <path
                                    d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z">
                                </path>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="https://in.pinterest.com/thekraftors/">
                            <svg class="text-14 w-5 h-5" viewBox="0 0 496 512" fill="#849493">
                                <path
                                    d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z">
                                </path>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/the_kraftors/">
                            <svg class="text-14 w-6 h-6" viewBox="0 0 512 512" fill="#849493">
                                <path
                                    d="M349.33,69.33H162.67C97.92,69.33,48,119.25,48,184v186.67C48,392.75,97.92,442.67,162.67,442.67h186.67C414.08,442.67,464,392.75,464,327V140.33C464,119.25,414.08,69.33,349.33,69.33Zm77.57,257.17c0,43.77-35.52,79.29-79.29,79.29H164.4c-43.77,0-79.29-35.52-79.29-79.29V184.4c0-43.77,35.52-79.29,79.29-79.29H347.61c43.77,0,79.29,35.52,79.29,79.29v142.1Z">
                                </path>

                                <path
                                    d="M256,149.33a106.67,106.67,0,1,0,106.67,106.67A106.79,106.79,0,0,0,256,149.33Zm0,160A53.33,53.33,0,1,1,309.33,256,53.37,53.37,0,0,1,256,309.33Z">
                                </path>
                                <circle cx="392.67" cy="119.33" r="25.6"></circle>

                            </svg>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!--end container-->
</footer><!--end footer-->
<!-- End Footer -->