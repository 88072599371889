<app-navbar></app-navbar>

<section class="py-20 bg-gray-100">
  <div class="container mx-auto px-6 lg:px-8 text-gray-800">
    <div class="text-center mb-12 mt-20">
      <h1 class="text-4xl font-bold text-gray-900">Terms of Use</h1>
      <p class="text-sm font-semibold text-gray-600 mt-2">Last Updated: September 16, 2024</p>
    </div>

    <div class="bg-white shadow-md rounded-lg p-8">
      <p class="text-base mb-8">
        Welcome to Image Enhancer ("we", "our", "us"). These Terms of Use ("Terms") govern your use of our website
        <a href="https://www.thekraftors.com" class="text-blue-500 underline">www.thekraftors.com</a> and the services provided
        through it ("Services"). By accessing or using our website and Services, you agree to be bound by these
        Terms. If you do not agree with these Terms, please do not use our website or Services.
      </p>

      <ol class="list-decimal space-y-6 ms-5">
        <li class="text-lg font-semibold">Use of Our Services</li>
        <p class="text-base">
          By using our website and Services, you acknowledge that you have read, understood, and agree to be bound
          by these Terms, as well as our Privacy Policy. We reserve the right to update or modify these Terms at
          any time, and your continued use of the website and Services constitutes acceptance of those changes.
        </p>

        <li class="text-lg font-semibold">Acceptance of Terms</li>
        <ul class="space-y-4 pl-6 list-disc">
          <li>
            <span class="font-semibold">Eligibility: </span> 
            You must be at least 18 years old or have the consent of a parent or guardian to use our Services.
          </li>
          <li>
            <span class="font-semibold">Account Registration: </span>
            Some features of our Services may require you to create an account. You agree to provide accurate and complete information and to update your account information as necessary.
          </li>
          <li>
            <span class="font-semibold">Prohibited Activities: </span>
            You agree not to engage in any unlawful activities, including but not limited to unauthorized access to our systems, distributing malware, or engaging in fraudulent activities.
          </li>
        </ul>

        <li class="text-lg font-semibold">Intellectual Property</li>
        <ul class="space-y-4 pl-6 list-disc">
          <li>
            <span class="font-semibold">Ownership: </span>
            All content, software, and other materials provided on our website and through our Services are the property of Image Enhancer or its licensors and are protected by intellectual property laws.
          </li>
          <li>
            <span class="font-semibold">License: </span>
            We grant you a limited, non-exclusive, non-transferable license to access and use our Services for your personal or internal business purposes. This license does not include any resale or commercial use of our Services.
          </li>
        </ul>

        <li class="text-lg font-semibold">User Content</li>
        <ul class="space-y-4 pl-6 list-disc">
          <li>
            <span class="font-semibold">Responsibility: </span>
            You are solely responsible for any content you upload, post, or otherwise make available through our Services. You grant us a worldwide, royalty-free, perpetual, and irrevocable license to use, reproduce, modify, and display such content.
          </li>
          <li>
            <span class="font-semibold">Content Moderation: </span>
            We reserve the right to remove or modify any user-generated content that we deem inappropriate or in violation of these Terms.
          </li>
        </ul>

        <li class="text-lg font-semibold">Privacy</li>
        <p class="text-base">
          Your use of our Services is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information.
        </p>

        <li class="text-lg font-semibold">Disclaimers and Limitation of Liability</li>
        <ul class="space-y-4 pl-6 list-disc">
          <li>
            <span class="font-semibold">Disclaimer: </span>
            Our Services are provided "as is" and "as available" without any warranties of any kind, either express or implied. We do not guarantee that our Services will be uninterrupted or error-free.
          </li>
          <li>
            <span class="font-semibold">Limitation of Liability: </span>
            To the fullest extent permitted by law, Image Enhancer shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of our Services.
          </li>
        </ul>

        <li class="text-lg font-semibold">Indemnification</li>
        <p class="text-base">
          You agree to indemnify, defend, and hold harmless Image Enhancer and its affiliates from and against any claims, liabilities, damages, losses, and expenses arising out of or related to your use of our Services or any violation of these Terms.
        </p>

        <li class="text-lg font-semibold">Termination</li>
        <p class="text-base">
          We reserve the right to terminate or suspend your access to our Services at our sole discretion, without notice, for any reason, including but not limited to any violation of these Terms.
        </p>

        <li class="text-lg font-semibold">Governing Law</li>
        <p class="text-base">
          These Terms shall be governed by and construed by the Indian Law without regard to its conflict of law principles. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in Lucknow, Uttar Pradesh, India.
        </p>

        <li class="text-lg font-semibold">Contact Us</li>
        <p class="text-base">
          If you have any questions or concerns about these Terms, please contact us at:
        </p>
        <ul class="space-y-4 pl-6 list-disc">
          <li>
            <span class="font-semibold">Email: </span> 
            <a href="mailto:ai@thekraftors.com" class="text-blue-500 underline">{{email}}</a>
          </li>
          <li>
            <span class="font-semibold">Address: </span> 
            3rd Floor Shagun Palace, Opp. Mr. Brown Sapru Marg, Hazratganj lko - 226001
          </li>
        </ul>

        <p class="text-base font-semibold text-center mt-10">Thank you for using Image Enhancer!</p>
      </ol>
    </div>
  </div>
</section>

<app-footer></app-footer>
