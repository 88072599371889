<section class="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="contact">
    <div class="container">
      <div class="grid grid-cols-1 pb-8 text-center">
        <h6 class="text-orange-600 text-base font-medium uppercase mb-2">Contact us</h6>
        <h3 class="mb-4 md:text-2xl text-xl font-medium dark:text-white">Get In Touch !</h3>
        <p class="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">
          Launch your campaign and benefit from our expertise on designing and managing conversion centered Tailwind CSS html page.
        </p>
      </div>
  
      <div class="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-6">
        <div class="lg:col-span-8">
          <div class="p-6 rounded-md shadow bg-white dark:bg-slate-900">
             <!-- Success Message -->
          <div *ngIf="successMessage" class="mb-5 text-green-600">{{ successMessage }}</div>

          <!-- Error Message -->
          <div *ngIf="errorMessage" class="mb-5 text-red-600">{{ errorMessage }}</div>

            <!-- Bind the form to Angular's Reactive Form -->
            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
              <div class="grid lg:grid-cols-12 lg:gap-6">
                <div class="lg:col-span-6 mb-5">
                  <!-- Name field with formControlName -->
                  <input name="name" id="name" type="text" formControlName="name" class="form-input" placeholder="Name :">
                </div>
  
                <div class="lg:col-span-6 mb-5">
                  <!-- Email field with formControlName -->
                  <input name="email" id="email" type="email" formControlName="email" class="form-input" placeholder="Email :">
                </div>
              </div>
  
              <div class="grid grid-cols-1">
                <div class="mb-5">
                  <!-- Subject field with formControlName -->
                  <input name="subject" id="subject" class="form-input" formControlName="subject" placeholder="Subject :">
                </div>
  
                <div class="mb-5">
                  <!-- Comments field with formControlName -->
                  <textarea name="comments" id="comments" class="form-input textarea h-28" formControlName="comments" placeholder="Message :"></textarea>
                </div>
              </div>
              
              <!-- Submit button -->
              <button type="submit" id="submit" name="send"
                class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md h-11 justify-center flex items-center">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>