

<!-- Start -->
<section class="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="about">
    <div class="container mx-auto">
        <div class="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
            <div class="lg:col-span-5">
                <div class="relative">
                    <img src="assets/images/about.jpg" class="rounded-lg shadow-lg relative" alt="">
                    <div class="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                        <a href="javascript:void(0);" appVideopopup data-target="https://www.youtube.com/embed/S_CGed6E610" data-type="youtube" data-id="yba7hPeTSjk"
                            class="size-20 rounded-full shadow-lg shadow-slate-100 dark:shadow-slate-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-orange-600">
                            <i class="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                        </a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="lg:col-span-7">
                <div class="lg:ms-7">
                    <h6 class="text-orange-600 text-base font-medium uppercase mb-2">Who We Are ?</h6>
                    <h3 class="mb-4 md:text-2xl text-xl font-medium dark:text-white">Our Company Story</h3>

                    <p class="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">Start working with Upwind that can
                        provide everything you need to genevfvffrate awareness, drive traffic, connect. Dummy text is text
                        that is used in the publishing industry or by web designers to occupy the space which will later
                        be filled with 'real' content. This is required when, for example, the final text is not yet
                        available. Dummy texts have been in use by typesetters since the 16th century.</p>

                    <div class="relative mt-10">
                        <a href="#portfolio"
                            class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md">View
                            Portfolio</a>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end grid-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->
 