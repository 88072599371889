<!-- Navbar Start -->
<nav class="navbar " id="navbar" [ngClass]="scrolled ? 'is-sticky' : 'is-sticky' ">
    <div class="px-10 flex flex-wrap items-center ">
        <ng-container *ngIf="currentRoute; else elseTemplate">
            <a class="navbar-brand " routerLink="/index">
                <span class="inline-block dark:hidden">
                    <img src="assets/images/kraftors_logo.png" class="l-dark w-20 h-full" alt="">
                    <img src="assets/images/kraftors_white.png" class="l-light w-20 h-full" alt="">
                </span>
                <img src="assets/images/kraftors_white.png" class="hidden dark:inline-block" alt="">
            </a>
        </ng-container>
        <ng-template #elseTemplate>
            <a class="navbar-brand" href="/">
                <img src="assets/images/kraftors_logo.png" class="inline-block dark:hidden w-20 h-full" alt="">
                <img src="assets/images/kraftors_white.png" class="hidden dark:inline-block w-20 h-full" alt="">
            </a>
        </ng-template>


        <div class="nav-icons flex items-center ms-auto">
           
            <!-- Navbar Collapse Manu Button -->
            <button data-collapse="menu-collapse" type="button" (click)="toggleMenu()"
                class="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
                aria-controls="menu-collapse" aria-expanded="false">
                <span class="sr-only">Navigation Menu</span>
                <i class="mdi mdi-menu mdi-24px"></i>
            </button>
        </div>
        <!-- Navbar Manu -->
        <div class="navigation lg_992:order-1 lg_992:flex hidden ms-end" id="menu-collapse">

            <ul class="navbar-nav items-center" [class.nav-light]="currentRoute" id="navbar-navlist">
                <li class="nav-item ms-0 home active">
                    <a class="nav-link t active" href="/index#home" >Home</a>
                </li>
                <li class="nav-item ms-0 about">
                    <a class="nav-link" href="{{baseurl}}about-us">About us</a>
                </li>
                <li class="nav-item ms-0 features">
                    <a class="nav-link" href="{{baseurl}}aiml" >Our Services</a>
                </li>
                <li class="nav-item ms-0 portfolio">
                    <a class="nav-link" href="{{baseurl}}case-study-jewellery-ecommerce" >Case Studies</a>
                </li>
                <li class="nav-item ms-0 blog">
                    <a class="nav-link" href="{{baseurl}}blog/" >Blog</a>
                </li>
                <li class="nav-item ms-0 email">
                    <h6 class="nav-link">
                        <span class="">E-mail us at</span><br>
                        <a class="text-yellow-600" href="mailto:{{email}}">{{email}}</a>
                    </h6>
                </li>
               
            </ul>
            <!-- <h6 class="nav-item ms-10 email" id="navbar-navlist">
                  <span class="nav-link">E-mail us at</span>
                  <br>
                  <a class="nav-link text-yellow-600" href="/index#blog">{{email}}</a>
            </h6> -->
            
           
        </div>
    </div>
</nav>
<!-- Navbar End -->