import { Component } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders, HttpEventType, HttpEvent } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { of, timer, Subject } from 'rxjs';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent {
  contactForm: FormGroup;
  errorMessage = '';
  successMessage = '';

  constructor(private http: HttpClient, private fb: FormBuilder) {
    // Initialize the form with form controls
    this.contactForm = this.fb.group({
      name: ['', Validators.required],   // Name field
      email: ['', [Validators.required, Validators.email]],  // Email field
      subject: ['', Validators.required],  // Subject field
      comments: ['', Validators.required]  // Comments field
    });
  }

  // Function to handle form submission
  onSubmit() {
    if (this.contactForm.valid) {
      // Extract form data
      const formData = this.contactForm.value;
  
      // Define headers if necessary
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
  
      // Make the HTTP POST request to the API
      this.http.post('/feedback/image-enhancer-contact', formData, { headers })
        .pipe(
          catchError(error => {
            console.error('Contactus submission failed', error);
            this.errorMessage = 'Contactus submission failed. Please try again.';
            return of(null); // Handle error gracefully
          })
        )
        .subscribe(response => {
          this.successMessage = 'Thank You for contacting us. We will get back to you shortly!';
          this.contactForm.reset();
        });
  
    } else {
      this.errorMessage = "Please fill the required fields."
    }
  }
  
}