import { Component } from '@angular/core';

@Component({
  selector: 'app-term-use',
  // standalone: true,
  // imports: [],
  templateUrl: './term-use.component.html',
  styleUrl: './term-use.component.scss'
})
export class TermUseComponent {
email :any='ai@thekraftors.com';
}
